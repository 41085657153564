import { ColorModeScript, ChakraProvider,theme } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import store from './integration/store/store.js';

import App from './App';
import Payment from './Payment';

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';


import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

const trackingId = "G-7ZCDJLTR8V"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

ReactDOM.render(
  <StoreProvider store={store}>
  <StrictMode>
    <ChakraProvider theme={theme}>
    <ColorModeScript />
    <Router>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/Payment" element={<Payment/>} />
      </Routes>
    </Router>
    </ChakraProvider>
  </StrictMode>
  </StoreProvider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
