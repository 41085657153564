// write me a function that takes in a file prices.csv and parses it
import React, { useState, useEffect } from 'react';
import { RadioGroup, Radio, Stack, Text } from '@chakra-ui/react';
import csvData from './prices.csv';



const RoomPrices = ({ setProductCode }) => {
    const [roomPrices, setRoomPrices] = useState(new Map())
    useEffect(() => {
        const parseCSV = () => {
            const results = new Map()
            const nightTypes = []
            for (let i = 1; i < csvData[0].length; i++) {
                nightTypes.push(capitalize(csvData[0][i]))
            }
            for (let i = 0; i < nightTypes.length; i++) {
                results.set(nightTypes[i], new Map());
            }

            for (let i = 1; i < csvData.length; i++) {
                const roomType = capitalize(csvData[i][0]);
                for (let j = 1; j < csvData[i].length; j++) {
                    const price = parseFloat(csvData[i][j]);
                    const nightType = nightTypes[j - 1];
                    results.get(nightType).set(roomType, price);
                }
            }

            return results;
        }
        setRoomPrices(parseCSV())
    }, [])

    return (
        <RadioGroup defaultValue="P001">
            <Stack>
                {
                    roomPrices.size > 0 && Array.from(roomPrices.keys()).map(nightType => {
                        return (
                            <Text>{nightType + " (Tax Inclusive 10%)"}
                                <Stack>
                                    {
                                        Array.from(roomPrices.get(nightType).keys()).map((roomType, idx) => {
                                            return (
                                                /* effective 2024-03-25 */
                                                <Radio key={idx} size="lg" name="productCode" colorScheme="gray" value={`${nightType},${roomType}`}
                                                    onChange={event => setProductCode(event.currentTarget.value)} >
                                                    {roomType} • HK${roomPrices.get(nightType).get(roomType)} per room per night
                                                </Radio>
                                            )
                                        })
                                    }</Stack>
                            </Text>
                        )
                    })
                }
            </Stack>
        </RadioGroup>
    )

}

const capitalize = (str) => str.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export default RoomPrices;
