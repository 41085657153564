import {
  VisuallyHidden,
  
  chakra,
  Link,
  Box,
  HStack,
  VStack,
  Text
} from '@chakra-ui/react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import React from 'react';

export default function Socials() {
  return (
    <Box>
      <VStack>
        <HStack direction={'row'} spacing={6} >
          <Link href='https://www.instagram.com/twentyonewhitfield' isExternal>
            <SocialButton label={'Instagram'} >
              <FaInstagram />
            </SocialButton>
          </Link>
          <Link href='https://www.facebook.com/twentyonewhitfield' isExternal>
            <SocialButton label={'Facebook'} >
              <FaFacebookF />
            </SocialButton>
          </Link>
          <Link href='https://www.linkedin.com/company/twentyonewhitfield' isExternal>
            <SocialButton label={'LinkedIn'} >
              <FaLinkedinIn />
            </SocialButton>
          </Link>
        </HStack>
        <HStack fontSize="xs" fontWeight="thin" pt={2} color='gray'>
          <Text>+852 3994 8585 | reservations@twentyonewhitfield.com</Text>
        </HStack>
      </VStack>

    </Box>
  )
}

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={'#fafafa'}
      // bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={12}
      h={12}
      cursor={'pointer'}
      as={'a'}
      color={'gray.800'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        color: 'gray.500',
        transform: "scale(0.98)",
        transition: 'all .1s ease',
        // bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
      _active={{
        color: 'gray.500',
        transform: "scale(0.96)",
        transition: 'all .1s ease',
        // bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}

    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};
