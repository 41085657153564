
import { configureStore } from '@reduxjs/toolkit';
import hotelReducer from '../reducer/hotelReducer';


export default configureStore({
  reducer: {
    hotelDetail: hotelReducer,

  },
});
