import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import store from './integration/store/store.js';
import {
  Center,
  ChakraProvider,
  Box,
  VStack,
  Grid,
  Image,
  Stack,
  theme,
  useBreakpointValue,
  chakra,
  GridItem,
  Flex
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import Day from './assets/hero-day.jpeg';
import Night from './assets/hero-night.jpeg';
import polaone from './assets/pola-1.png';
import polatwo from './assets/pola-2.png';
import polathree from './assets/pola-3.png';

import Promotion from './promotion';
import Designers from './designers';
import Promotioncontainertwo from './promotioncontainertwo';

//import Payment from './Payment';

import Navbar from './navbar';
import Footer from './footer';




function App() {
  return (
    <StoreProvider store={store}>
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        {/* <Payment /> */}
        <Navbar />
        <Grid
          minH="100vh"
          p={3}>
          <ColorModeSwitcher color='white' justifySelf="flex-end" />
          <VStack spacing={8}>
            <Box maxW='vh'>
              <Flex
                px={6} flex={1} align={'center'} justify={'center'} textAlign='center'>
                <chakra.p color='gray.600'
                  p={3}
                  fontSize={{ base: 'xx-small', md: 'xs' }}
                  letterSpacing={2}
                  fontWeight='300'
                  textAlign='center'
                  textTransform='uppercase'
                  lineHeight='0.9'
                  fontStyle='italic'
                >
                  Slide between Day☼ and Night☽ views
                </chakra.p>
              </Flex>
              <Box
                zIndex='-9999'>
                <Render />
              </Box>
            </Box>
          </VStack>
        </Grid>
      </Box>

      <Promotion />
      <Promotioncontainertwo />
      <Designers />
      <Center
        mt={{ base: '8', md: '40' }}
        mb={{ base: '20', md: '64' }}>
        <VStack>

          <BgHero />
        </VStack>
      </Center>

      <Box w='100%' >

      </Box>

      <Footer />
    </ChakraProvider>
    </StoreProvider>
  );
}

export default App;

function Render() {
  return (
    <>

      <Flex
        w={'full'}

        h={'50vh'}
        size={'cover'}
        position={'center,center'}
      // minW='1000px'
      // objectFit="cover"
      // boxSize={'1100px'}
      // style={{ width: 700, height: 450 }}
      >

        <ImgComparisonSlider

          sliderWidth={3}
          sliderColor="red"
          handleColor="red"
          handleBackgroundColor="white"
          focus={{
            outline: 'none',
          }}
          value="63"
          hover="hover"
        >
          <img slot="first" alt1='day' alt='' src={Day} />
          <img slot="second" alt2='night' alt='' src={Night} />

        </ImgComparisonSlider>
      </Flex>
    </>
  );
}

function BgHero() {
  return (
    // <Flex
    //   w={'full'}

    //   h={'100vh'}
    //   m={40}
    //   // backgroundImage={Hero}
    //   alt='polaroids'
    //   // backgroundSize={'cover'}
    //   // backgroundPosition={'center center'}
    //   position={'center,center'}
    //   >
    <Center
      mt={-30}>
      <Stack
        // w={'full'}
        justify={'center'}
        direction={{ base: 'column', md: 'row' }}
        columns={{ base: '1', md: '3' }}
        px={useBreakpointValue({ base: 4, md: 8 })}
        w={{ base: '450px', md: '6xl' }}
      >
        <GridItem pt={{ base: '00', md: '0' }}
          align='center'>
          <Image
            maxW={{ base: '300px', md: 'flex' }}
            src={polaone} alt='polaroid-1' />
        </GridItem >

        <GridItem
          pt={{ base: '180', md: '0' }}
          align='center'>
          <Image
            src={polatwo}
            maxW={{ base: '300px', md: 'flex' }}
            alt='polaroid-2' />
        </GridItem>

        <GridItem
          align='center'>
          <Image
            src={polathree}

            maxW={{ base: '300px', md: 'flex' }}
            alt='polaroid-3' />
        </GridItem>

      </Stack>
    </Center>
  );
}