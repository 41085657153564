

//export const server_path='http://hotel-web-api-uat.yintran.com/api/unauth/room/';
//export const server_path='http://hotel-web-api-uat.yintran.com/api/unauth/room/reservation';

//export const server_path='http://hotel-web-api-uat.yintran.com//api/unauth/room/book/BN20210924181301';

//export const server_path='http://localhost:3000/ws/';

export const server_path='https://hotel-web-api.yintran.com/api/unauth/room/';

export async function client(endpoint,object) {



const config = {
    method: 'POST',
    headers: {
       Accept: 'application/json',
       'Content-Type': 'application/json',
    },
   // mode: 'no-cors',
    //body: JSON.stringify(content)
    body:JSON.stringify(object)
  }
  //let body={};
  //body.header={};
  //body.data=content;
  //config.body = JSON.stringify(body);


  let url=server_path+endpoint
  
  console.log("Check:"+url);
  
  console.log("Check booking data:"+JSON.stringify(object));

  try {    
    const response = await fetch(url, config);
    let json = await response.json();
     console.log("Check return json:"+JSON.stringify(json));
    return json;
    //throw new Error(err)
  } catch (err) {
    console.error(err)
  }

   
}

client.get = function (endpoint) {

  return client(endpoint,null)
}

client.post = function (endpoint,object) {

  return client(endpoint,object)
}

