import {
  Box,
  chakra,
  Flex,
  AspectRatio,
  Stack,
  Text,
  Center,
  Spacer,
  HStack,
  IconButton,
  Image,
  Grid,
  GridItem,
  VStack,
  List,
  ListItem
} from '@chakra-ui/react';

import React, { } from "react";
// import ContactInfoGoogleMapsSplit from './ContactInfoGoogleMapsSplit';
import { ChevronRightIcon } from '@chakra-ui/icons';

import florent from './assets/designers/designers-florent-nedelec-img.jpeg';
import joe from './assets/designers/designers-joseph-fung-img.jpeg';

import designone from './assets/design/design-img-01.jpeg';
import designtwo from './assets/design/design-img-02.jpeg';

import roomtypeone from './assets/roomtype/bed-img-01.jpeg';
import Reserve from './components/reserveModal/reserveModal';
import amenities from './assets/amenities/amenities-services-img.jpeg';

export default function Designers() {
  return (
    <Box>

       <Box mt={{ base: 20, md: 40 }}>
        <Neighbourhood />
        < Map  px={6}/>
      </Box>

      <Box mt={{ base: 20, md: 40 }} px={6}><div id="design"></div><Design /></Box>
      <Box mt={{ base: 20, md: 40 }}><div id="rooms"></div><Rooms /></Box>
      <Box mt={{ base: 20, md: 40 }} px={6}><div id="designers"></div><Joe /></Box>
      <Box mt={{ base: 20, md: 40 }} ><div id="rooms"></div><Florent /></Box>
      <Box mt={{ base: 20, md: 40 }} px={6}><div id="services"></div><Servicesandfacilities /></Box>
      <Box mt={{ base: 20, md: 40 }}><Seeforyourself /></Box>
    </Box>
  );
}


function Joe() {
  return (

    <Stack
      // minH={'100vh'} 
      direction={{ base: 'column', md: 'row-reverse' }}>
      <Flex 
        // px={6} 
        flex={1} align={'center'} justify={'center'} textAlign='center'>
        <Stack 
        spacing={6} 
        maxW={'lg'}
        w={'full'} 
        >

          <chakra.p 
            color='gray.400'
            fontSize='sm'
            letterSpacing={2}
            fontWeight='medium'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mb={2}
          >
            interior architect
          </chakra.p>
          <chakra.p
            fontFamily='playfair'
            fontSize='4xl'
            letterSpacing={2}
            color={'black'}
            fontWeight='thin'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mt={"180px"}
            mb={"40px"}
          >
            Joseph Fung
          </chakra.p>
          <Text textAlign='left' fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
            Held in high esteem by his industry peers, architect and designer Joseph Fung is
            known for bringing a bold and masterful aesthetic to residential and hospitality
            properties. A Hong Kong native, Mr. Fung received his master’s degree in architecture
            from Harvard University in the United States. Mr. Fung is the principal of SP2, an architectural
            and design firm that undertakes projects in Hong Kong and abroad.
          </Text>
        </Stack>
      </Flex>
      <Flex flex={1} >
      <Center
          w={'100%'}
          h={{ base: '300px', md: '600px' }}
          mx={{ base: 0, md: 40 }}
          backgroundImage={joe}
          alt='polaroids'
          backgroundSize={'cover'}
          backgroundPosition={'right right'}
        // position={'center,center'}  
        >
        </Center>
      </Flex>
    </Stack>
  );
}

function Florent() {
  return (
    <Stack
      // minH={'100vh'} 
      direction={{ base: 'column', md: 'row' }}>
      <Flex 
      px={6} 
      flex={1} align={'center'} justify={'center'} textAlign='center'>
        <Stack spacing={6} w={'full'} 
        maxW={'lg'}
        >
          <chakra.p color='gray.400'
            fontSize='sm'
            letterSpacing={2}
            fontWeight='medium'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mb={2}
          >
            exterior architect
          </chakra.p>

          <chakra.p
            fontFamily='playfair'
            fontSize='4xl'
            letterSpacing={2}
            color={'black'}
            fontWeight='thin'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mt={"180px"}
            mb={"40px"}
          >
            Florent Nédélec
          </chakra.p>
          <Text textAlign='left' fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
            Exterior Architect Florent Nédélec designs uniquely sophisticated buildings that establish aesthetic
            harmony with their geographic environment through minimalism and clarity. Educated in France and the
            United States, Mr. Nédélec holds a professional degree in architecture from l’École d’Architecture de
            Paris-la-Villette in Paris and a Master of Architecture from the Georgia Institute of Technology in Atlanta.
            His past work includes prestigious skyscrapers in New York, Moscow, Dubai, and Asia.
          </Text>
        </Stack>
      </Flex>
      <Flex flex={1} >
        <Center
          w={'100%'}
          h={{ base: '300px', md: '600px' }}
          mx={{ base: 6, md: 40 }}
          backgroundImage={florent}
          alt='polaroids'
          backgroundSize={'cover'}
          backgroundPosition={'right right'}
        // position={'center,center'}  
        >

          {/* <Image
        p={4}
        boxSize={{sm:"580px"}}
        objectFit="cover"
        alt='Intro'
        src={florent}
      /> */}
        </Center>
      </Flex>
    </Stack>

  );
}

function Rooms() {
  return (
    <VStack
      // minH={'100vh'} 
      direction={{ base: 'column', md: 'row-reverse' }}>
      
      <Center
        w={'100%'}
        px={6}
        // h='100vh'
        h={{ base: '300px', md: '100vh' }}
        mx={{ base: 20, md: 40 }}
        bgGradient={'linear(to-l, blackAlpha.600, transparent)'}
        backgroundImage={roomtypeone}
        alt='amenities'
        backgroundSize={'cover'}
        position={'center,center'}
      >
        <Flex px={6} flex={1} align={'center'} justify={'center'} textAlign='center'>
        <Stack spacing={6} w={'full'} maxW={'3xl'}>
          <Spacer />
          <chakra.p 
          // color='gray.400'
            fontSize='sm'
            letterSpacing={2}
            // color={'gray.800'}
            fontWeight='medium'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mb={2}
            color='white'
          >
            rooms {'&'} suites
          </chakra.p>


          <chakra.p
            fontFamily='playfair'
            fontSize='4xl'
            letterSpacing={2}
            // color={'black'}
            fontWeight='thin'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mt={"180px"}
            mb={"40px"}
            color='white'
          >
            Home away from home
          </chakra.p>
          <Center>
            <Flex
              direction='column'
              fontFamily='playfair'
              fontSize='lg'
              letterSpacing={2}
              // color={'gray.600'}
              fontWeight='thin'
              textTransform='uppercase'
              lineHeight='1.2'
              // mt={"180px"}
              // mb={"40px"}
              color='white'
              textAlign='center'>
              <Text w='256px'>Full Harbour View</Text>
              <Spacer />
              <Text w='256px'>Harbour View</Text>
              <Spacer />
              <Text w='256px'>Deluxe</Text>
            </Flex>
          </Center>
          <Text
           color='white'
          textAlign='left' fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} 
          // color={'gray.800'}
          >
            Elegant yet functional, Twenty One Whitfield’s suites feature
            floor-to-ceiling windows and are appointed with subtle luxuries.
            The higher floors offer unobstructed views of Victoria Harbour and
            the Central skyline. High-quality and well-equipped pantries are at
            hand, whether dining alone or entertaining.
          </Text>
          <Spacer />
        </Stack>
      </Flex>
      </Center>
    </VStack>

  );
}

function Servicesandfacilities() {
  return (
    <VStack
      // minH={'100vh'} 
      direction={{ base: 'column', md: 'row-reverse' }}>
      <Flex px={6} flex={1} align={'center'} justify={'center'} textAlign='center'>
        <Stack spacing={6} w={'full'} maxW={'3xl'}>
          <Spacer />
          <chakra.p color='gray.400'
            fontSize='sm'
            letterSpacing={2}
            // color={'gray.800'}
            fontWeight='medium'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mb={2}
          >
            your stay
          </chakra.p>

          <chakra.p
            fontFamily='playfair'
            fontSize='4xl'
            letterSpacing={2}
            color={'black'}
            fontWeight='thin'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mt={"180px"}
            mb={"40px"}
          >
            Services {'&'} Facilities
          </chakra.p>

          <Text textAlign='center' fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
            <Text
               direction='column'
               fontFamily='playfair'
               fontSize='lg'
               letterSpacing={2}
               color={'gray.600'}
               fontWeight='thin'
               textTransform='uppercase'
               lineHeight='1'
               >
              
            Featured services and facilities:</Text>
            <br />
            Daily housekeeping services
            <br />
            24-hour security with card access to main entrance and elevators
            <br />
            24-hour guest services and concierge services
            <br />
            Complimentary wireless and fixed line broadband internet connections
          </Text>
          <Spacer />
        </Stack>
      </Flex>

      <Stack
        // minH={'100vh'} 
        direction={{ base: 'column', md: 'row-reverse' }} maxW={'6xl'}>
        <Flex 
          px={6}
          spacing={6}
          flex={1} align={'center'} justify={'center'} textAlign='center'>
          <Grid spacing={8} w={'50'}  columns={{ base: 2 }}>
            {/* amenities */}
            <GridItem textAlign='left'>
              {/* <Center> */}
                <Flex
                  direction='column'
                  fontFamily='playfair'
                  fontSize='lg'
                  letterSpacing={2}
                  color={'gray.600'}
                  fontWeight='thin'
                  
                  textTransform='uppercase'
                  lineHeight='1.2'
                // mt={"180px"}
                // mb={"40px"}
                >
                  <Text w='256px'>Amenities</Text>
                </Flex>
              {/* </Center> */}
              <Text  fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
                <List>
                  <ListItem>Toshiba 40" full HD LED television</ListItem>
                  <ListItem>Philips DVD system with dock for iPod/iPhone</ListItem>
                  <ListItem>Personal safe</ListItem>
                  <ListItem>Siemens cordless phone with voicemail, direct line, and caller ID</ListItem>
                  <ListItem>Local TV, Now TV, and Cable TV channels</ListItem>
                  <ListItem>Iron and ironing board</ListItem>
                  <ListItem>Extra large working desk</ListItem>
                </List>
              </Text>
            </GridItem>


            {/* bedding */}
            <GridItem pt={2}  textAlign='left'>
              {/* <Center> */}
                <Flex
                  direction='column'
                  fontFamily='playfair'
                  fontSize='lg'
                  letterSpacing={2}
                  color={'gray.600'}
                  fontWeight='thin'
                  textTransform='uppercase'
                  lineHeight='1.2'
                  >
                  <Text w='256px'>bedding</Text>
                </Flex>
              {/* </Center> */}
              <Text  fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
                <List>
                  <ListItem>White goose down duvet and pillows</ListItem>
                  <ListItem>One Queen sized bed</ListItem>
                </List>
              </Text>
            </GridItem>

            {/* bathroom */}
            <GridItem pt={2} textAlign='left'
            >
              {/* <Center> */}
                <Flex
                  direction='column'
                  fontFamily='playfair'
                  fontSize='lg'
                  letterSpacing={2}
                  color={'gray.600'}
                  fontWeight='thin'
                  textTransform='uppercase'
                  lineHeight='1.2'
                  >
                  <Text w='256px'>bathroom</Text>
                </Flex>
              {/* </Center> */}
              <Text fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
                <List>
                  <ListItem>Rain shower</ListItem>
                  <ListItem>Bathtub in select suites</ListItem>
                  <ListItem>Hairdryer</ListItem>
                  <ListItem>L'Occitane amenities (Full Harbour View rooms only)</ListItem>
                </List>
              </Text>
            </GridItem>
            {/* pantry */}
            <GridItem pt={2} textAlign='left' >
              {/* <Center> */}
                <Flex
                  direction='column'
                  fontFamily='playfair'
                  fontSize='lg'
                  letterSpacing={2}
                  color={'gray.600'}
                  fontWeight='thin'
                  textTransform='uppercase'
                  lineHeight='1.2'>
                  <Text w='256px'>pantry</Text>
                </Flex>
              {/* </Center> */}
              <Text fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
                <List>
                  <ListItem>Crystal wine and beverage glasses</ListItem>
                  <ListItem>Tableware and cutlery</ListItem>
                </List>
              </Text>
            </GridItem>
          </Grid>
        </Flex>

        <Center
         w={'50'} 
          // w={'100%'}
        // h={{ base: '300px', md: '700px' }}
        // mx={{ base: 20, md: 40 }}
        // backgroundImage={amenities}
        // alt='amenities'
        // backgroundSize={'cover'}
        >
          <Image
            src={amenities}
            backgroundSize={'cover'}
          />
        </Center>
      </Stack>

    </VStack>
  );
}

function Neighbourhood() {
  return (
    <VStack
      // minH={'100vh'} 
      direction={{ base: 'column', md: 'row-reverse' }}>
      <Flex px={6} flex={1} align={'center'} justify={'center'} textAlign='center'>
        <Stack spacing={6} w={'full'} maxW={'3xl'}>
          <Spacer />
          <chakra.p color='gray.400'
            fontSize='sm'
            letterSpacing={2}
            // color={'gray.800'}
            fontWeight='medium'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mb={2}
          >
            where to find us in hong kong
          </chakra.p>

          <chakra.p
            fontFamily='playfair'
            fontSize='4xl'
            letterSpacing={2}
            color={'black'}
            fontWeight='thin'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mt={"180px"}
            mb={"40px"}
          >
            21 whitfield road
          </chakra.p>

          <Text textAlign='left' fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
            Twenty One Whitfield is located within easy walking
            distance to Causeway Bay’s luxury shopping destinations,
            including SOGO and Times Square. Just minutes by car or
            MTR from the Central business district, Twenty One Whitfield
            is a short stroll across the street from Hong Kong’s iconic
            public park, Victoria Park.
            <br />
            <br />
            Dining options, from traditional cha chaan teng to Japanese
            and Western cuisines are a stone’s throw away, along Electric
            Road, Tsing Fung Street and Tung Lo Wan Road.
          </Text>
          <Spacer />
        </Stack>
      </Flex>

      <Center >


      </Center>

    </VStack>

  );
}

function Map() {
  return (
    <Flex>
      <AspectRatio ratio={1} w="full"
        position={'relative'}
        h={'580px'}
        // rounded={'2xl'}
        // boxShadow={'2xl'}
        overflow={'hidden'}>

        <iframe
          style={{ filter: 'grayscale(100%)' }}
          // style={{ opacity: '0.7' }} 
          title="Twenty One Whitfield Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.79025704997!2d114.1885712149529!3d22.285933649047962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ffd27f111f%3A0x688977a1219c93a1!2s21%20Whitfield%20Rd%2C%20Causeway%20Bay!5e0!3m2!1sen!2shk!4v1629799998205!5m2!1sen!2shk"
          loading="lazy"

        />

      </AspectRatio>
    </Flex>


  );
}

function Design() {
  return (
    <VStack mt={{ base: 20, md: 40 }}
      direction={{ base: 'column', md: 'row-reverse' }}>
      <Flex 
      // px={6} 
      flex={1} align={'center'} justify={'center'} textAlign='center'>
        <Stack 
        spacing={6} 
        w={'full'} maxW={'3xl'}>
          <Spacer />
          <chakra.p color='gray.400' fontSize='sm' letterSpacing={2} fontWeight='medium' textAlign='center' textTransform='uppercase' lineHeight='0.9' mb={2}>
            design vision
            </chakra.p>
          <chakra.p
            fontFamily='playfair'
            fontSize='4xl'
            letterSpacing={2}
            color={'black'}
            fontWeight='thin'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mt={"180px"}
            mb={"40px"}
          >
            the suite life
          </chakra.p>
          <Text textAlign='left' fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
            Twenty One Whitfield’s suites have been designed in a harmonious
            palette of natural tones with interior designer Joseph Fung’s signature
            touches. Guests enjoy additional privacy at Twenty One Whitfield with only
            two suites per floor, and with the staggered layout of the two suites,
            guests in all rooms are afforded the best possible views. The focus of the
            design is to balance the suites’ aesthetics with the efficient use of space
            and the provision of optimal comfort.
          </Text>
          <Spacer />
        </Stack>
      </Flex>

      <Center >
        <Flex w='100%' direction={{ base: 'column', md: 'row-reverse' }}
        >
            <Image
              src={designone} />
            <Image src={designtwo} />
        </Flex>
      </Center>
    </VStack>
  );
}

function Seeforyourself() {
  return (
    <VStack
      // minH={'100vh'} 
      direction={{ base: 'column', md: 'row-reverse' }}>
      <Flex px={6} flex={1} align={'center'} justify={'center'} textAlign='center'>
        <Stack spacing={6} w={'full'} maxW={'3xl'} mb={16}>
          <Spacer />
          <chakra.p color='gray.400'
            fontSize='sm'
            letterSpacing={2}
            // color={'gray.800'}
            fontWeight='medium'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mb={2}
          >
            so what are you waiting for
          </chakra.p>

          <chakra.p
            fontFamily='playfair'
            fontSize='4xl'
            letterSpacing={2}
            color={'black'}
            fontWeight='thin'
            textAlign='center'
            textTransform='uppercase'
            lineHeight='0.9'
            mt={"180px"}
          // mb={"40px"}
          >
            come see for yourself
          </chakra.p>
        </Stack>
      </Flex>
      <Center >
        <Box
          border='2px'
          borderColor='black'
          py={2}
          px={2}
        >
          <HStack>
            <Reserve />
            <IconButton
              as={ChevronRightIcon}
              size='sm'
              // bg={useColorModeValue('pink.400', 'pink.800')}
              bg={'transparent'}
              color={'black'}
              borderColor={'transparent'}
              // borderWidth={1}
              // outlineColor={'gray.800'}
              variant={'outline'}
              // color={useColorModeValue('white', 'gray.800')}
              _hover={{
                transform: "scale(0.98)",
                color: 'gray.800',
                borderColor: 'transparent',
                // borderColor: 'gray.800'
              }}
              _active={{
                transform: "scale(0.95)",
                borderColor: 'transparent',
                color: 'gray.900',
                borderWidth: '2'
              }}
              aria-label="Subscribe"

              rounded={'full'}
            />
          </HStack>
        </Box>
      </Center>
    </VStack>

  );
}