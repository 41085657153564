import {
VStack,
Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Spacer,
  useColorModeValue,
  useDisclosure,
  HStack,
  Grid,
  chakra,
  Link
  
} from '@chakra-ui/react';
import {HamburgerIcon,CloseIcon,ChevronDownIcon,} from '@chakra-ui/icons';
import React from 'react';
import ReserveModal from 'components/reserveModal/reserveModal';

//import {
//  Link
//} from "react-router-dom";

import Socials from './components/soc';

// import {
//   Link
// } from "react-router-dom";

// const Reserve = () => {
//   return (
//     <Button
//     minW={'95px'}
//     display={{ base: 'flex', md: 'inline-flex' }}
//     fontSize={'sm'}
//     h={'35px'}
//     py={'0.5'}
//     px={'3'}
//     fontWeight={600}
//     letterSpacing={1}
//     color={'black'}
//     href={'#'}
//     bg={'transparent'}
//     _hover={{
//       transform: "scale(0.98)",
//       color: 'gray.800',
//       borderColor: 'gray.800',
//       bg:'transparent'
//     }}
//     _active={{
//       transform: "scale(0.96)",
//       color: 'gray.900',
//       borderWidth: '3',
//       bg:'transparent'
//     }}
    
//     >RESERVE</Button> 
//   );
// };
// const Close = () => {
//   return (
//     <Button
//     minW={'95px'}
//     display={{ base: 'flex', md: 'inline-flex' }}
//     fontSize={'sm'}
//     h={'35px'}
//     py={'0.5'}
//     px={'3'}
//     fontWeight={600}
//     letterSpacing={1}
//     color={'black'}
//     href={'#'}
//     bg={'transparent'}
//     _hover={{
//       transform: "scale(0.98)",
//       color: 'gray.800',
//       borderColor: 'gray.800',
//       bg:'transparent'
//     }}
//     _active={{
//       transform: "scale(0.96)",
//       color: 'gray.900',
//       borderWidth: '3',
//       bg:'transparent'
//     }}
    
//     >CLOSE</Button> 
//   );
// };

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        position={'fixed'}
        w={'full'}
        bg={'white'}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        align={'center'}
        zIndex={'900'}>
        {/* left menu */}
        
        <Flex flex={{ base: 'auto', md: 'auto' }} 
        mr={{ base: 'none', md: '70px' }} 
        // w='95px'
        // ml={{ base: -2 }} 
        display={{ base: 'flex', md: 'flex' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'} aria-label={'Toggle Navigation'}
          />
        </Flex>

        {/* logo */}
        <Flex 

        flex={{ base: 'auto' }} 
        >
          <Link href='#'>
            {/* <Logotextual/> */}
            <Stack
              direction={{base:'column',md:'row'}}
              mt={{ base: 'none', md: 1 }} 
              textAlign='center'
              // w='180px'
            >
              <Text textAlign='center'
                fontSize={{base:'xx-small',md:'lg'}}
                fontWeight='medium'
                textTransform="uppercase"
                letterSpacing={2}
                color={useColorModeValue('gray.900', 'white')}
              >twenty one
              </Text>
              <Text textAlign='center'
                fontSize={{ base: 'xx-small', md: 'lg' }}
                textTransform="uppercase"
                color={useColorModeValue('gray.900', 'white')}>
                <Text fontWeight='black' letterSpacing={3.5}>whitfield</Text>
              </Text>
            </Stack>
          </Link>
        </Flex>
        {/* sign in */}
        <HStack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          
          // spacing={6}
          >
          {/* <Signinmodal /> */}
          <ReserveModal />
        </HStack>
      </Flex>


      <Collapse in={isOpen} animateOpacity>
        <Grid minH={'100vh'}>
          <Spacer />
          <MobileNav />
          <Spacer />
        </Grid>
      </Collapse>


    </Box>
  );
}

const MobileNav = () => {
  return (
    <VStack >
      <HStack direction={{ sm: 'row', md: 'row' }} px={{ sm: '0', md: '10' }}
      >
        <Spacer />
        <Stack
          py={4}
          // pr={4}
          px={{ sm: '0', md: 4 }}
          minW={{ sm: '318', md: '350px' }}
        // direction={{sm:'column', md:'row'}}
        // maxW={{sm:'100', md:'350px'}}
        >
          {NAV_ITEMS.map((navItem) => (
            <MobileNavItem pt={'200px'} key={navItem.label} {...navItem} />
          ))}
        </Stack>
        <Spacer />
        <Stack
          minW={{ base: '100px', md: '350px' }}
          textAlign='left'
        >
          <chakra.p
            // pt={{ base: '100px', md: '40' }}
            fontWeight={'bold'}
            color={'gray.500'}
            fontSize={{ base: '10px', md: 'sm' }}
          >call</chakra.p>
          <chakra.p
            fontWeight={'normal'}
            fontSize={{ base: '12px', md: 'xl' }}
            lineHeight={'1.2'}
          >+852 3994 8585
          </chakra.p>

          <chakra.p
            pt={4}
            fontWeight={'bold'}
            color={'gray.500'}
            fontSize={{ base: '10px', md: 'sm' }}
          >email</chakra.p>
          <chakra.p
            fontWeight={'normal'}
            fontSize={{ base: '12px', md: 'xl' }}
            lineHeight={'1.2'}
          >reservations<br />@twentyonewhitfield.com</chakra.p>

          <chakra.p
            pt={4}
            fontWeight={'bold'}
            color={'gray.500'}
            fontSize={{ base: '10px', md: 'sm' }}
          >find us</chakra.p>
          <chakra.p
            fontWeight={'normal'}
            fontSize={{ base: '12px', md: 'xl' }}
            lineHeight={'1.2'}
          >21 Whitfield Road, <br />Causeway Bay, <br />Hong Kong </chakra.p>

        </Stack>
        <Spacer />
      </HStack>
      <Spacer />
      <Socials/>
    </VStack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}        
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Link href={href}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={200}
          // fontWeight={'thin'}
          fontSize={{ base: 'lg', md: '3xl' }}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        </Link>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: 'About',
  //   children: [
  //     {
  //       label: 'Services',
  //       subLabel: 'Call or email us to learn more and open an account',
  //       href: '#',
  //     },
  //     {
  //       label: 'Careers',
  //       subLabel: 'Learn about our values. Maybe even grow your career with us. See open roles.',
  //       href: '#',
  //     },
  //   ],
  // },
  // {
  //   label: 'Reservations',
  //   href: '#',
  // },
  {
    label: 'Designers',
    href: '#designers',
  },
  {
    label: 'Design',
    href: '#design',
  },
  {
    label: 'Rooms & Suites',
    href: '#rooms',
  },
  {
    label: 'Services & Facilities',
    href: '#services',
  },


  {
    label: 'Location',
    href: '#location',
  },
  {
    label: 'Contact us',
    href: '#contact',
  },
  /*{
    label: 'Confirm Your Booking',
    href: '/Payment',
  },
  */
  


];

// function Signinmodal() {
//   const { isOpen, onOpen, onClose } = useDisclosure()
//   return (
//     <>
//       <Button onClick={onOpen}
//         display={{ base: 'none', md: 'inline-flex' }}
//         as={'a'}
//         fontSize={'sm'}
//         fontWeight={400}
//         // variant={'link'}
//         href={'#'}
//         bg={'transparent'}
//         _hover={{
//           transform: "scale(0.98)",
//           transition: 'all .1s ease',
//           color: 'gray.500',
//           bg: 'transparent'
//         }}
//         _active={{
//           transform: "scale(0.96)",
//           transition: 'all .1s ease',
//           color: 'gray.800',
//         }}
//       >Sign in</Button>

//       <Modal isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay />
//         <ModalContent flex="center">
//           <ModalHeader>
//             <HStack mt={{ base: 'none', md: 2 }} minW='157px'>
//               <Image src={logo}
//                 textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
//                 h={4} />
//               <Text
//                 fontSize='12px'
//                 fontWeight='medium'
//                 textTransform="uppercase"
//                 letterSpacing={2}
//                 color={useColorModeValue('gray.900', 'white')}
//               >5d leading tech</Text>
//             </HStack>
//           </ModalHeader>
//           <ModalCloseButton rounded='full' />
//           <ModalBody>
//             {/* <Signininput /> */}
//           </ModalBody>
//           {/* <ModalFooter>
//             <Button variant="ghost">Close</Button>
//             <Button colorScheme="pink" mr={3} onClick={onClose}>
//               Close
//             </Button>
//           </ModalFooter> */}
//         </ModalContent>
//       </Modal>
//     </>
//   )
// }
