import {
  Box,
  chakra,
  Flex,
  Stack,
  HStack,
  Link,
  Text,
  VisuallyHidden,
  Input,
  Image,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  useDisclosure,
  Center,
  Select,
  FormControl,
  Checkbox,
  Button,
  IconButton,
  
  Spacer} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import React, {  } from "react";
import promotion from './assets/promotion.jpg';
import logofull from './assets/logo-full.svg';
import { ChevronRightIcon } from '@chakra-ui/icons';


export default function Promotion() {
  return (
    <Stack 
    // minH={'100vh'} 
    direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'} textAlign='center'>
        <Stack spacing={6} w={'full'} maxW={'lg'}>
          <Flex>
            <Spacer />
            <Image
              w='200px'
              alt={'Twenty One Whitfield Hotels and Serviced Apartments'}
              objectFit={'cover'}
              src={logofull}
            />
            <Spacer />
          </Flex>
          <Text textAlign='left' fontWeight='light' fontSize={{ base: 'sm', lg: 'sm' }} color={'gray.800'}>
            Experience Hong Kong like you've never seen it before. With the silhouette of the
            Victoria Harbour complementing the quiet contours of deep thought
            or the kindred company of good conversation, this panoramic skyline will leave you an
            indelible impression of why you're proud to call this place your home
            - or your favourite travel destination.
            {/* Let us show you what makes us fall in love with this City over and over. */}
          </Text>
          <chakra.p
            fontSize='lg'
            fontFamily='serif'
            fontStyle='italic'
            textAlign='right'
            pt={8}
          >Extraordinary views {'&'} inspired encounters with a <br />City like no other</chakra.p>
          <Flex align='center'>
            <Spacer />
            <Box
              border='2px'
              borderColor='black'
              py={2}
              px={2}
            >
              <HStack>
                <ReserveModal />
                <IconButton
                  as={ChevronRightIcon}
                  size='sm'
                  // bg={useColorModeValue('pink.400', 'pink.800')}
                  bg={'transparent'}
                  color={'black'}
                  borderColor={'transparent'}
                  // borderWidth={1}
                  // outlineColor={'gray.800'}
                  variant={'outline'}
                  // color={useColorModeValue('white', 'gray.800')}
                  _hover={{
                    transform: "scale(0.98)",
                    color: 'gray.800',
                    borderColor: 'transparent',
                    // borderColor: 'gray.800'
                  }}
                  _active={{
                    transform: "scale(0.95)",
                    borderColor: 'transparent',
                    color: 'gray.900',
                    borderWidth: '2'
                  }}
                  aria-label="Subscribe"

                  rounded={'full'}
                />
              </HStack>
            </Box>
            {/* <Spacer /> */}
          </Flex>
          <Spacer />
        </Stack>
      </Flex>
      <Flex flex={1} 
      // align={'center'} 
      // justify={'center'} 
      >
        <Center>
          <Image
            p={4}
            boxSize={{sm:"580px"}}
            objectFit="cover"
            alt='Intro'
            src={promotion}
          />
        </Center>
      </Flex>
    </Stack>
  );
}
//footer buttons



function ReserveModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen}
        minW={'95px'}
        display={{ base: 'flex', md: 'inline-flex' }}
        fontSize={'sm'}
        h={'35px'}
        py={'0.5'}
        px={'3'}
        fontWeight={600}
        letterSpacing={1}
        color={'black'}
        href={'#'}
        bg={'transparent'}
        _hover={{
          transform: "scale(0.98)",
          color: 'gray.800',
          borderColor: 'gray.800',
          bg: 'transparent'
        }}
        _active={{
          transform: "scale(0.96)",
          color: 'gray.900',
          borderWidth: '3',
          bg: 'transparent'
        }}
      >RESERVE</Button>

      <Modal isOpen={isOpen} onClose={onClose}
      zIndex={'9999'}
      bottom={0} h='100%' w='100%' size='full'
      mx={14}
      >
        {/* <ModalOverlay /> */}
        
        <ModalContent m={1}>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          
          <ModalCloseButton p={5} 
            _focusVisible='false'
            outline='none'
            _hover={{
              transform: "scale(0.98)",
              color: 'gray.800',
              borderColor: 'gray.800',
            }}
            _active={{
              transform: "scale(0.96)",
              color: 'gray.900',
              borderWidth: '3'
            }}

          />
          <ModalBody>
            <VStack>
              <Spacer />
              <Booking />
              <Spacer />
              <Socials />
              <Spacer />
            </VStack>
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}
        
        </ModalContent>
        
      </Modal>
    </>
  )
}


function Booking() {
  return (
    <Flex
      // minH={'100vh'}
      align={'center'}
      justify={'center'}
    // bg={useColorModeValue('white', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'}  py={12} px={6} align='center'>
        {/* <Spacer/> */}
          {/* <Logotextual/> */}
          <Image w={{base: "200px",md: "250px"}} src={logofull} alt='Twenty One Whitfield Hotels and Serviced Apartments'/>
          {/* <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
          </Text> */}
        <Box
          // rounded={'lg'}
          // bg={useColorModeValue('white', 'gray.800')}
          // boxShadow={'lg'}
          
          borderColor='gray.300'
          // px={10}
          // pb={10}
          >
          <Stack spacing={4} 
          minW={{base: "320px",md: "410px"}}
          maxW={{base: "320px",md: "lg"}}
          // minW='410px' 
          >
            {/* <chakra.p fontSize='md'textAlign='center' fontWeight='thin'>21 Whitfield Road, Causeway Bay, Hong Kong</chakra.p> */}
            <Stack pt={2} direction={{base:'column',md:'row'}}>
              <FormControl id="arrival">
                <Input type="date" />
              </FormControl>
              <FormControl id="departure">
                <Input type="date" />
              </FormControl>
            </Stack>

            <HStack>
              <Select placeholder="Adults">
                <option value="option1">1 Guest</option>
                <option value="option2">2 Guests</option>
              </Select>
              <Select placeholder="Children">
                <option value="option1">0 Children</option>
                <option value="option2">1 Child</option>
                <option value="option2">2 Children</option>
                <option value="option3">Call (+852 3994 8585) or email (reservations@twentyonewhitfield.com) if further accomodation is needed</option>
              </Select>
              <Select placeholder="Rooms">
                <option value="option1">1 Room</option>
                <option value="option2">2 Rooms</option>
                <option value="option3">3 Rooms</option>
                <option value="option4">4 Rooms</option>
                <option value="option5">5 Rooms</option>
                <option value="option6">6 Rooms</option>
                <option value="option7">Call (+852 3994 8585) or email (reservations@twentyonewhitfield.com) if more rooms are needed
                </option>
              </Select>
            </HStack>

            <HStack>
              <FormControl id="rewardsnumber">
                <Input type="rewardsnumber" placeholder="Your 21WF Rewards #" />
              </FormControl>
              <FormControl id="promotionalcode">
                <Input type="promotionalcode" placeholder="Promotional Code" />
              </FormControl>
            </HStack>

            <Stack pt={6}>
              <Checkbox colorScheme="gray" size="sm" fontWeight='semibold' >USE POINTS / CERTIFICATES</Checkbox>
              <Checkbox colorScheme="gray"size="sm" fontWeight='semibold'>MY DATES ARE FLEXIBLE</Checkbox>
            </Stack>
            {/*
            <FormControl id="email">
              <Input type="email" placeholder="Basic usage"/>
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" />
            </FormControl> */}
            <Stack spacing={10}>
              {/* <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Checkbox>Remember me</Checkbox>
                <Link color={'blue.300'}>Forgot password?</Link>
              </Stack> */}
              <Button
              mt={4}
                bg={'black'}
                // fontSize={'sm'}
                // letterSpacing={1}
                // textTransform={'uppercase'}
                color={'gray.100'}
                _hover={{
                  bg: 'black',
                  color:'white',
                  transform: "scale(0.98)",

                  // bgGradient: 'linear(to-tr, orange.400, orange.500)'

                }}
                _active={{
                  bg: 'gray.800',
                  transform: "scale(0.96)",
                  // bgGradient: 'linear(to-tr, orange.400, orange.500)'

                }}
                
                >
                Check availability
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}


function Socials() {
  return (
    <Stack direction={'row'} spacing={6} >
      <Link href='https://www.facebook.com/twentyonewhitfield' isExternal>
        <SocialButton label={'Instagram'} >
          <FaInstagram />
        </SocialButton>
      </Link>
      <Link href='https://www.facebook.com/twentyonewhitfield' isExternal>
        <SocialButton label={'Facebook'} >
          <FaFacebookF />
        </SocialButton>
      </Link>
      <Link href='https://www.linkedin.com/company/twentyonewhitfield' isExternal>
        <SocialButton label={'LinkedIn'} >
          <FaLinkedinIn />
        </SocialButton>
      </Link>
    </Stack>
  )
}

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={'#fafafa'}
      // bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={12}
      h={12}
      cursor={'pointer'}
      as={'a'}
      color={'gray.800'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        color: 'gray.500',
        transform: "scale(0.98)",
        transition: 'all .1s ease',
        // bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
      _active={{
        color: 'gray.500',
        transform: "scale(0.96)",
        transition: 'all .1s ease',
        // bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}

    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};
