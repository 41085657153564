import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Center,
  Spacer,
  Box,
  VStack,
  Image,
  Stack,
  Button,
  chakra,
  Flex,
  Text,
  FormControl,
  Input,
  HStack,
  Select,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import logofull from './assets/logo-full.svg'
import Socials from './components/soc';
import fivedpaylogo from './assets/fivedpaylogo.svg';

import {
  Link, useLocation
} from "react-router-dom";

import { fetchBooking, selectBooking } from './integration/reducer/hotelReducer';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Payment() {

  let query = useQuery();
  console.log(query.get("bn"));

  const dispatch = useDispatch();
  const bookingData = useSelector(selectBooking);

  useEffect(() => {
    dispatch(fetchBooking(query.get("bn")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  console.log("check:" + bookingData);

  return (
    <>
      {/* <Navbar /> */}
      <VStack minH={'100vh'}
        bg='#fafafa'
        pb={16}>

        {/*<Box py={4}><Link fontSize="xs"> Display in your browser </Link> </Box>*/}
        <Center >

          <Box py={6} px={8}
            bg='white'>
            <Box>
              <Center minH='90vh'>
                <VStack spacing={8} textAlign='left'>
                  <Link to='/App'><Image
                    pt={8}
                    w={{ base: "150px", md: "180px" }}
                    src={logofull}
                    alt='Twenty One Whitfield Hotels and Serviced Apartments'
                  /></Link>
                  <BookingDetails bookingData={bookingData} />
                  <Button
                    mt={4}
                    bg={'black'}
                    fontSize={'sm'}
                    letterSpacing={1}
                    textTransform={'uppercase'}
                    color={'gray.100'}
                    _hover={{
                      bg: 'black',
                      color: 'white',
                      transform: "scale(0.98)",
                    }}
                    _active={{
                      bg: 'gray.800',
                      transform: "scale(0.96)",
                    }}
                  >
                    submit payment</Button>
                  <Center><Socials /></Center>
                </VStack>
              </Center>
            </Box>
          </Box>

        </Center>
        {/* <Footer /> */}
      </VStack>
    </>
  );
}

function BookingDetails(props) {
  return (
    <Flex
      align={'center'}
      justify={'center'}
    >
      <Stack spacing={8} mx={'auto'}
        // py={12} 
        px={6} align='center'>
        {/* <Image w={{ base: "200px", md: "250px" }} src={logofull} alt='Twenty One Whitfield Hotels and Serviced Apartments' /> */}
        <Box
          borderColor='gray.300'
        >
          <Stack spacing={4}
            minW={{ base: "320px", md: "410px" }}
            maxW={{ base: "320px", md: "lg" }}
          >
            <Stack pt={6} color='gray.800' size="md" fontWeight='light'>
              <Text fontSize="3xl" fontWeight='thin'>Your Booking Is Eligible for</Text>
              <VStack>

                <HStack>
                  <Flex textAlign='center'
                    align='center'
                    justify='center' w={'100%'}>
                    <InstantRebate />
                    <Ampersand />
                    <LoyaltyRewardPoints />
                    <Ampersand />
                    <BookTenOneFree />
                    <Ampersand />
                    <SpecialUpgradeRate />
                  </Flex>
                </HStack>
              </VStack>
              <Text fontSize='lg' lineHeight={2}>
                <Text pt={4} fontSize="3xl" fontWeight='thin'>Room Type</Text>
                <Text >{props.bookingData.productCode}
                  HK$950 per Room per Night
                </Text>

                <Text pt={4} fontSize="3xl" fontWeight='thin'>Your Stay</Text>
                <Text >Check In: {props.bookingData.arrivalDate} (after 12:00pm)</Text>
                <Text  >Check Out: {props.bookingData.departureDate} (before 3:00pm)</Text>
                {/*<Text  >Nights: {props.bookingData.departureDate} </Text>*/}
                <Text >Guests: {props.bookingData.guestsPerRoom} adults, 1 child</Text>

                <Text pt={4} fontSize="3xl" fontWeight='thin'>Your Contact</Text>
                <Text  >Name: {props.bookingData.salutation} {props.bookingData.firstName} {props.bookingData.lastName}</Text>
                <Text  >Phone number: +{props.bookingData.countryCode} {props.bookingData.phoneNumber}</Text>
                <Text  >Email: {props.bookingData.email}</Text>


                {/*
                <Text pt={4} fontSize="3xl" fontWeight='thin'>Rewards</Text>

                <Text >21WF Rewards #: 809 908 908</Text>
                <Text >Promotional Code Applied: 8090 0980</Text>
                <Text >1. Reward Points Earned: 10,000</Text>
                <Text fontSize='sm'> Points to Date: 20,000</Text>
                <Text fontSize='sm'>Points Applied: 30,000</Text>
                <Text fontSize='sm'>Points upon Booking Completion: 0</Text>
                <Text >2. Instant Rebate Earned: HKD 400 (10 Nights x HKD 40 Rebate)</Text>

                <Text >3. 3% Further Discount on a Booking of 10 Nights</Text>
                <Text >4. Complimentary Stay (10 Nights Booked)</Text>
                <Text >5. Eligible for Special Discount Rate on Complimentary Stay</Text>
                <Checkbox fontWeight='semibold' colorScheme="gray" 
                size='md'
                >Use Special Discount Rate on Complimentary Stay</Checkbox>
                */}
                <Text pt={4} fontSize="3xl" fontWeight='thin'>Select Payment Method</Text>
                <chakra.p
                  fontWeight={'light'}
                  color={'gray.600'}
                  fontSize={'sm'}
                  pb={4}>
                  Special rate made possible by 5D Pay
                  {/* {oldrate} */}
                </chakra.p>
                {/* <Text fontWeight='light' >Please use to enjoy these rewards </Text> */}

                <PaymentMethod bookingData={props.bookingData} />
              </Text>
            </Stack>

            <Stack spacing={10}>
              <Text
                fontSize='sm'
                fontWeight='thin'>
                Terms and Conditions <br />
                • Special offers are available upon booking via this online form or via phone or email reservations@twentyonewhitfield.com
                <br />
                • Upon submission of this form, we will contact you with the pricing and availability
                for your to confirm.
                Your reservation is not yet confirmed until then.
                <br />
                • Full prepayment is required for the entire stay at time of reservation
                and will be charged to the credit card provided. The amount is
                non-refundable in the event of any cancellation or amendment.
                <br />
                • The rate is subject to a 10% service charge {'&'} prevailing government
                tax (currently 0%) per room per night.
                <br />
                • Advance reservation is required.
                <br />
                • Please use this form to contact us regarding the final rate. <br />
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

function InstantRebate() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize={{ base: 'sm', md: 'xl' }}
      lineHeight='0.9'
      fontWeight='thin'>
      instant <br />rebate</Text>
  );
}
function LoyaltyRewardPoints() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize={{ base: 'sm', md: 'xl' }}
      lineHeight='0.9'
      fontWeight='thin'>
      loyalty <br />reward points</Text>
  );
}

function BookTenOneFree() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize={{ base: 'sm', md: 'xl' }}
      lineHeight='0.9'
      fontWeight='thin'>
      book 10 nights <br />get 1 free</Text>
  );
}

function SpecialUpgradeRate() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize={{ base: 'sm', md: 'xl' }}
      lineHeight='0.9'
      fontWeight='thin'>
      special <br /> upgrade rate</Text>
  );
}

function Ampersand() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize='xl'
      lineHeight='0.9'
      color='gray.300'
      fontWeight='thin'>
      {'&'}</Text>
  );
}

// const promotions = [
//   {
//     oldrate: '1,430',
//     newrate: '1,340',
//   },
// ];
// interface PackageDetails {
//   oldrate: string;
//   newrate: string;
//   index: number;
// }

function PaymentMethod(props) {
  // const { oldrate, newrate, index } = props;
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <HStack>
                <Image src={fivedpaylogo} maxH='28px' />
                <Flex align={'right'}>
                  <Stack direction={{ base: 'row', md: 'row' }} align={'center'}>
                    <chakra.p
                      fontWeight={'semibold'}
                      fontSize={'md'}>
                      {'HKD'} {props.bookingData.totalAmountDesc}
                      {/* {newrate} */}
                      {/* {' '}{'/ night'} */}
                    </chakra.p>
                    <chakra.p
                      fontWeight={'medium'}
                      textDecoration={'line-through'}
                      color={'gray.400'}
                      fontSize={{ base: 'xs', md: 'sm' }}
                    >
                      {'HKD'} {props.bookingData.totalAmountDesc}
                      {/* {oldrate} */}
                    </chakra.p>
                  </Stack>
                </Flex>

              </HStack>
            </Box>
            <AccordionIcon />
          </AccordionButton>

        </h2>
        <AccordionPanel pb={4}>
          <Fivedoay bookingData={props.bookingData} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>

        <Center h={10}>
          <Text fontWeight='thin'>or</Text>
        </Center>

      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>

            <Box flex="1" textAlign="left">
              <HStack>
                <Text textAlign="left">Credit Card </Text>
                <chakra.p
                  fontWeight={'semibold'}
                  fontSize={'md'}>
                  {'HKD'} {props.bookingData.totalAmountDesc}
                  {/* {newrate} */}
                  {/* {' '}{'/ night'} */}
                </chakra.p>

                <chakra.p
                  fontWeight={'medium'}
                  fontStyle={'italic'}
                  // textDecoration={'line-through'} 
                  color={'gray.400'}
                  fontSize={{ base: 'xs', md: 'sm' }}
                >
                  Original

                  {/* {oldrate} */}
                </chakra.p>
              </HStack>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <CreditCard />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}


function CreditCard() {
  return (
    <Box>
      <Text pt={4} fontSize="3xl" fontWeight='thin'>Credit Card Details</Text>
      <Text
        fontSize='sm'
        fontWeight='thin'>
        By entering your card details, you authorize us to charge your card to confirm your booking with us.</Text>
      <HStack py={2}>
        <Box minW='140px' >
          <Select placeholder="Credit Card" >
            <option value="option1" >Master</option>
            <option value="option2">Visa</option>
            <option value="option2">UPI</option>
            <option value="option2">Other</option>
          </Select>
        </Box>
        <FormControl id="cardname">
          <Input type="string" placeholder="Card Holder Name" />
        </FormControl>
      </HStack>
      <Spacer />

      <HStack py={2}>
        <FormControl id="cardnumber">
          <Input type="number" placeholder="Card Number" />
        </FormControl>
        <Box maxW='25%'>
          <FormControl id="cvv">
            <Input type="number" placeholder="CVV/CVC" />
          </FormControl>
        </Box>
      </HStack>
      <HStack maxW='50%' py={2}>
        <Select placeholder='mm'>
          <option value="option1">01</option>
          <option value="option2">02</option>
          <option value="option2">03</option>
          <option value="option2">04</option>
          <option value="option2">05</option>
          <option value="option1">06</option>
          <option value="option2">07</option>
          <option value="option2">08</option>
          <option value="option2">09</option>
          <option value="option2">10</option>
          <option value="option1">11</option>
          <option value="option2">12</option>
        </Select>

        <Select placeholder="yyyy">
          <option value="option1">2021</option>
          <option value="option2">2022</option>
          <option value="option1">2023</option>
          <option value="option2">2024</option>
          <option value="option2">2025</option>

        </Select>
      </HStack>
    </Box>
  );
}


function Fivedoay(props) {
  return (
    <Box>
      <Text pt={4} fontSize="3xl" fontWeight='thin'>Scan QR</Text>
      <Flex direction='column' align='center'>
        <Text
          fontSize='sm'
          fontWeight='thin'>
          Scan this QR code with your 5D Pay app to make this payment and enjoy this special rate.</Text>

        <QRCode value={props.bookingData.paymentRecord ? props.bookingData.paymentRecord.payInfo : ''} />
      </Flex>
    </Box>
  );
}