import {
  Box,
  chakra,
  HStack,
  Image,
  VStack,
  Center} from '@chakra-ui/react';
import React, {  } from "react";
import promotiontwo from './assets/promotiontwo.png';


export default function Promotion() {
  return (
    <Box pt={'190px'}>
    <VStack
    // minH={'100vh'} 
    // direction={{ base: 'column', md: 'row' }}
    pt={'80px'}
    pb={'30px'}
    bg='black'
    >
      <chakra.p color='gray.400'
      fontSize='sm'
      letterSpacing={2}
      // color={'gray.800'}
      fontWeight='medium'
      textAlign='center'
      textTransform='uppercase'
      lineHeight='0.9'
      mb={2}
      >
Starting September 2021
      </chakra.p>
      <chakra.p
        fontFamily='playfair'
        fontSize='4xl'
        letterSpacing={2}
        color={'white'}
        
        fontWeight='thin'
        textAlign='center'
        textTransform='uppercase'
        lineHeight='0.9'
        // pb={8}
        mt={"180px"}
        mb={"40px"}
      >
        
      New offers {'&'} Loyalty Rewards 
    </chakra.p>
    

      <Center
      bg='black'
      w='100%'>
        <HStack>
          <Box
          >
          <Image
            p={4}
            // boxSize={{ sm: "580px" }}
            // boxSize='xl'
            h='300px'
            objectFit="cover"
            alt='Intro'
            src={promotiontwo}
          /></Box>
          {/* <Image
            p={4}
            // boxSize={{ sm: "580px" }}
            // boxSize='xl'
            h='300px'
            objectFit="cover"
            alt='Intro'
            src={picthis}
          />
          <Image
            p={4}
            // boxSize={{ sm: "580px" }}
            // boxSize='xl'
            h='300px'
            objectFit="cover"
            alt='Intro'
            src={nite}
          />
          <Image
            p={4}
            // boxSize={{ sm: "580px" }}
            // boxSize='xl'
            h='300px'
            objectFit="cover"
            alt='Intro'
            src={balloons}
          /> */}



        </HStack>
      </Center>

      
    </VStack>
    </Box>
  );
}
//footer buttons


