import { useDispatch } from 'react-redux';
import {
  VStack,
  Box,
  Flex,
  Button,
  Stack,
  Spacer,
  useDisclosure,
  HStack,
  Image,
  Select,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Center,
  ModalBody,
  FormControl,
  Text,
  Input,
  Checkbox,
  Radio, RadioGroup
} from '@chakra-ui/react';

import React, { useState } from 'react';

import logofull from 'assets/logo-full.svg'
import Socials from '../soc';

import appstore from 'assets/appstore.svg'
import googleplay from 'assets/google-play-badge.png'

import { submitBooking } from '../../integration/reducer/hotelReducer';
import RoomPrices from './RoomPrices';


export default function ReserveModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();




  return (
    <>
      <Button onClick={onOpen}
        minW={'95px'}
        display={{ base: 'flex', md: 'inline-flex' }}
        fontSize={'sm'}
        h={'35px'}
        py={'0.5'}
        px={'3'}
        fontWeight={600}
        letterSpacing={1}
        color={'black'}
        href={'#'}
        bg={'transparent'}
        _hover={{
          transform: "scale(0.98)",
          color: 'gray.800',
          borderColor: 'gray.800',
          bg: 'transparent'
        }}
        _active={{
          transform: "scale(0.96)",
          color: 'gray.900',
          borderWidth: '3',
          bg: 'transparent'
        }}
      >RESERVE</Button>

      <Modal isOpen={isOpen} onClose={onClose}
        zIndex={'9999'}
        bottom={0} h='100%' w='100%' size='full'
        mx={14}
      >
        {/* <ModalOverlay /> */}

        <ModalContent m={1}>
          {/* <ModalHeader>Modal Title</ModalHeader> */}

          <ModalCloseButton p={5}
            _focusVisible='false'
            outline='none'
            _hover={{
              transform: "scale(0.98)",
              color: 'gray.800',
              borderColor: 'gray.800',
            }}
            _active={{
              transform: "scale(0.96)",
              color: 'gray.900',
              borderWidth: '3'
            }}

          />
          <ModalBody>
            <VStack>
              <Spacer />
              <Booking />
              <Spacer />
              <Socials />
              <Spacer />
            </VStack>
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}

        </ModalContent>

      </Modal>
    </>
  )
}

// function Socials() {
//   return (
//     <VStack>
//       <Stack direction={'row'} spacing={6} >
//       <Link href='https://www.instagram.com/twentyonewhitfield' isExternal>
//         <SocialButton label={'Instagram'} >
//           <FaInstagram />
//         </SocialButton>
//       </Link>
//       <Link href='https://www.facebook.com/twentyonewhitfieldhongkong' isExternal>
//         <SocialButton label={'Facebook'} >
//           <FaFacebookF />
//         </SocialButton>
//       </Link>
//       <Link href='https://www.linkedin.com/company/twentyonewhitfield' isExternal>
//         <SocialButton label={'LinkedIn'} >
//           <FaLinkedinIn />
//         </SocialButton>
//       </Link>
//     </Stack>
//      <Text fontSize='sm'
//                 fontWeight='thin' align='center'
//                 letterSpacing={0.5}
//                 >
//      <br />
//      +852 3994 8585  |  
//      reservations@twentyonewhitfield.com </Text>
//      </VStack>
//   )
// }

// const SocialButton = ({
//   children,
//   label,
//   href,
// }: {
//   children: ReactNode;
//   label: string;
//   href: string;
// }) => {
//   return (
//     <chakra.button
//       bg={'#fafafa'}
//       // bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
//       rounded={'full'}
//       w={12}
//       h={12}
//       cursor={'pointer'}
//       as={'a'}
//       color={'gray.800'}
//       href={href}
//       display={'inline-flex'}
//       alignItems={'center'}
//       justifyContent={'center'}
//       transition={'background 0.3s ease'}
//       _hover={{
//         color: 'gray.500',
//         transform: "scale(0.98)",
//         transition: 'all .1s ease',
//         // bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//       }}
//       _active={{
//         color: 'gray.500',
//         transform: "scale(0.96)",
//         transition: 'all .1s ease',
//         // bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//       }}

//     >
//       <VisuallyHidden>{label}</VisuallyHidden>
//       {children}
//     </chakra.button>
//   );
// };


interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}


// function Signinmodal() {
//   const { isOpen, onOpen, onClose } = useDisclosure()
//   return (
//     <>
//       <Button onClick={onOpen}
//         display={{ base: 'none', md: 'inline-flex' }}
//         as={'a'}
//         fontSize={'sm'}
//         fontWeight={400}
//         // variant={'link'}
//         href={'#'}
//         bg={'transparent'}
//         _hover={{
//           transform: "scale(0.98)",
//           transition: 'all .1s ease',
//           color: 'gray.500',
//           bg: 'transparent'
//         }}
//         _active={{
//           transform: "scale(0.96)",
//           transition: 'all .1s ease',
//           color: 'gray.800',
//         }}
//       >Sign in</Button>

//       <Modal isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay />
//         <ModalContent flex="center">
//           <ModalHeader>
//             <HStack mt={{ base: 'none', md: 2 }} minW='157px'>
//               <Image src={logo}
//                 textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
//                 h={4} />
//               <Text
//                 fontSize='12px'
//                 fontWeight='medium'
//                 textTransform="uppercase"
//                 letterSpacing={2}
//                 color={useColorModeValue('gray.900', 'white')}
//               >5d leading tech</Text>
//             </HStack>
//           </ModalHeader>
//           <ModalCloseButton rounded='full' />
//           <ModalBody>
//             {/* <Signininput /> */}
//           </ModalBody>
//           {/* <ModalFooter>
//             <Button variant="ghost">Close</Button>
//             <Button colorScheme="pink" mr={3} onClick={onClose}>
//               Close
//             </Button>
//           </ModalFooter> */}
//         </ModalContent>
//       </Modal>
//     </>
//   )
// }

function Booking() {


  const dispatch = useDispatch();


  const [children, setChildren] = useState('');
  const [promotionalcode, setPromotionalCode] = useState('');
  const [rewardsnumber, setRewardsNumber] = useState('');
  //const [fhv, setFHV] = useState('');
  //const [hv, setHV] = useState('');
  //const [deluxe, setDeluxe] = useState('');

  const [rewardpointstrue, setRewardPointsTrue] = useState('');
  const [flexibledatestrue, setFlexibleDatesTrue] = useState('');




  const [productCode, setProductCode] = useState('P001');

  const [phone, setPhone] = useState('');
  const [phoneInValid, setPhoneInValid] = useState(false);

  const [country, setCountry] = useState('');
  const [countryInValid, setCountryInValid] = useState(false);

  const [email, setEmail] = useState('');
  const [emailInValid, setEmailInValid] = useState(false);

  const [arrival, setArrival] = useState('');
  const [arrivalInValid, setArrivalInValid] = useState(false);

  const [departure, setDeparture] = useState('');
  const [departureInValid, setDepartureInValid] = useState(false);

  const [adults, setAdults] = useState('');
  const [adultsInValid, setAdultsInValid] = useState(false);

  const [rooms, setRooms] = useState('');
  const [roomsInValid, setRoomsInValid] = useState(false);

  const [prefix, setPrefix] = useState('');
  const [prefixInValid, setPrefixInValid] = useState(false);

  const [firstname, setFirstName] = useState('');
  const [firstNameInValid, setFirstNameInValid] = useState(false);

  const [lastname, setLastName] = useState('');
  const [lastnameInValid, setLastNameInValid] = useState(false);

  const [showResult, setShowResult] = useState(false);


  const doSubmitBooking = () => {


    let error = false;

    if (firstname === '') { setFirstNameInValid(true); error = true; }
    if (lastname === '') { setLastNameInValid(true); error = true; }
    if (prefix === '') { setPrefixInValid(true); error = true; }

    if (country === '') { setCountryInValid(true); error = true; }
    if (phone === '') { setPhoneInValid(true); error = true; }
    if (email === '') { setEmailInValid(true); error = true; }

    if (arrival === '') { setArrivalInValid(true); error = true; }
    if (departure === '') { setDepartureInValid(true); error = true; }

    if (adults === '') { setAdultsInValid(true); error = true; }
    if (rooms === '') { setRoomsInValid(true); error = true; }

    if (!error) {

      var data = {
        phoneNumber: phone,
        salutation: prefix,
        firstName: firstname,
        lastName: lastname,
        countryCode: country,
        email: email,
        noOfRooms: rooms,
        guestsPerRoom: adults,
        noOfChildren: children,
        arrivalDate: arrival,
        departureDate: departure,
        productCode: productCode,
        promotionalCode: promotionalcode,
        rewardsNumber: rewardsnumber,
        rewardPointsTrue: rewardpointstrue,
        flexibleDatesTrue: flexibledatestrue
      }
      //alert(JSON.stringify(data));
      dispatch(submitBooking(data));
      setShowResult(true);
    }


  }


  //{event => (event) =>{setAdults(event.currentTarget.value);onChangeGuest()} 

  return (

    <Flex
      align={'center'}
      justify={'center'}>
      <Stack spacing={8} mx={'auto'} py={12} px={6} align='center'>
        <Image w={{ base: "200px", md: "250px" }} src={logofull} alt='Twenty One Whitfield Hotels and Serviced Apartments' />
        <Box borderColor='gray.300'>
          <Stack spacing={4}
            minW={{ base: "320px", md: "410px" }}
            maxW={{ base: "320px", md: "lg" }}>
            <Stack pt={6} color='gray.800' size="md" fontWeight='light'>
              {/* <Text fontSize="3xl" fontWeight='bold'>Current Offers x 5D Pay</Text> */}
              {/* <Text
                fontSize='md'
                lineHeight='1.3'
                pb='2'>
                Download and pay with 5D Pay to be eligible for
                a selection of rewards to choose from once we receive your booking enquiry. */}

                {/* We will send you a selection of rewards you are eligible
                to choose from once we receive your booking enquiry.  */}
              {/* </Text> */}
              {/* <HStack py={2}>
                <Spacer />
                <Link href='https://apps.apple.com/hk/app/5d-pay/id1503312483' isExternal>
                  <Image
                    w={{ base: "150px" }}
                    src={appstore} alt='Download 5D Pay on App Store' />
                </Link>
                <Spacer />
                <Link href='https://play.google.com/store/apps/details?id=com.yintran.pay&hl=en&gl=US' isExternal>

                  <Image
                    w={{ base: "150px" }}
                    href={{}}
                    src={googleplay}
                    alt='Download 5D Pay on Google Play' />
                </Link>
                <Spacer />
              </HStack> */}

              {/* <VStack>
                <HStack>
                  <Flex textAlign='center'
                    align='center'
                    justify='center' w={'100%'}>
                    <InstantRebate />
                    <Ampersand />
                    <LoyaltyRewardPoints />
                    <Ampersand />
                    <BookTenOneFree />
                    <Ampersand />
                    <SpecialUpgradeRate />
                  </Flex>
                </HStack>
              </VStack> */}

              <Text
               pt={4} 
                fontSize='lg'
                // lineHeight='1.3'
                fontWeight='normal'
                // pb='2'
                >

                
                Please submit a form for each room type you'd like to book:
              </Text>
              <RoomPrices setProductCode={setProductCode}/>
      
            </Stack>

            {/* <chakra.p fontSize='md'textAlign='center' fontWeight='thin'>21 Whitfield Road, Causeway Bay, Hong Kong</chakra.p> */}

            {/* your details */}
            <Stack >
              <Text pt={4} fontSize="3xl" fontWeight='thin'>Your Stay</Text>
              <Stack pt={0} direction={{ base: 'column', md: 'row' }}>

                <FormControl id="arrival" >
                  <Input type="date" isRequired
                    isInvalid={arrivalInValid}
                    errorBorderColor="red.300"
                    onChange={event => setArrival(event.currentTarget.value)}
                  />
                </FormControl>
                <FormControl id="departure" >
                  <Input type="date" isRequired
                    isInvalid={departureInValid}
                    errorBorderColor="red.300"
                    onChange={event => setDeparture(event.currentTarget.value)}
                  />
                </FormControl>
              </Stack>

              <HStack>
                <Select placeholder="Adults" isRequired
                  onChange={event => { setAdults(event.currentTarget.value); setRooms(Math.ceil(event.currentTarget.value / 2)) }}
                  isInvalid={adultsInValid}
                  errorBorderColor="red.300"
                >
                  <option value="1">1 Guest</option>
                  <option value="2">2 Guests</option>
                  <option value="3">3 Guests</option>
                  <option value="4">4 Guests</option>
                  <option value="5">5 Guests</option>
                  <option value="6">6 Guests</option>
                  <option value="Call">6+ Guests, please call us at +852 3994 8585</option>

                </Select>
                <Select placeholder="Children"
                  onChange={event => setChildren(event.currentTarget.value)}
                >
                  <option value="0">0 Children</option>
                  <option value="1">1 Child</option>
                  <option value="2">2 Children</option>
                  <option value="3">3 Children</option>
                  <option value="4">4 Children</option>
                  <option value="5">5 Children</option>
                  <option value="Call">Call (+852 3994 8585) or email (reservations@twentyonewhitfield.com) if further accomodation is needed</option>
                </Select>

                <Select placeholder="Rooms" isRequired value={rooms}
                  onChange={event => setRooms(event.currentTarget.value)}
                  isInvalid={roomsInValid}
                  errorBorderColor="red.300"
                >
                  <option value="1">1 Room</option>
                  <option value="2">2 Rooms</option>
                  <option value="3">3 Rooms</option>
                  <option value="4">4 Rooms</option>
                  <option value="5">5 Rooms</option>
                  <option value="6">6 Rooms</option>
                  <option value="7">Call (+852 3994 8585) or email (reservations@twentyonewhitfield.com) if more rooms are needed
                  </option>
                </Select>
              </HStack>
              <HStack>
                <FormControl id="rewardsnumber">
                  <Input type="rewardsnumber" placeholder="Your 21WF Rewards #"
                    onChange={event => setRewardsNumber(event.currentTarget.value)}
                  />
                </FormControl>
                <FormControl id="promotionalcode">
                  <Input type="promotionalcode" placeholder="Promotional Code"
                    onChange={event => setPromotionalCode(event.currentTarget.value)}
                  />
                </FormControl>
              </HStack>
            </Stack>

            {/* your details */}
            <Stack >
              <Text pt={4} fontSize="3xl" fontWeight='thin'>How We'll Contact You</Text>
              <Stack pt={0} direction={{ base: 'column', md: 'row' }}>
                <HStack minW='95px'>
                  <Select placeholder="Prefix" isRequired
                    isInvalid={prefixInValid}
                    errorBorderColor="red.300"
                    onChange={event => setPrefix(event.currentTarget.value)}
                  >
                    <option value="MR">Mr.</option>
                    <option value="MS">Ms.</option>
                    <option value="MRS">Mrs.</option>
                    <option value="MX">Mx.</option>
                    <option value="DR">Dr.</option>
                  </Select>

                </HStack>
                <FormControl id="firstname" isRequired >
                  <Input type="name" placeholder='First Name'
                    isInvalid={firstNameInValid}
                    errorBorderColor="red.300"
                    onChange={event => setFirstName(event.currentTarget.value)}
                  />
                </FormControl>
                <FormControl id="lastname" isRequired>
                  <Input type="name" placeholder='Last Name'
                    isInvalid={lastnameInValid}
                    errorBorderColor="red.300"
                    onChange={event => setLastName(event.currentTarget.value)}
                  />
                </FormControl>
              </Stack>

              <HStack>
                <Box maxW='95px'>
                  <FormControl id="country">
                    <Input type="string" placeholder="Country Code"
                      isInvalid={countryInValid}
                      errorBorderColor="red.300"
                      onChange={event => setCountry(event.currentTarget.value)}
                    />
                  </FormControl>
                </Box>
                <FormControl id="phone">
                  <Input type="number" placeholder="Phone Number"
                    isInvalid={phoneInValid}
                    errorBorderColor="red.300"
                    onChange={event => setPhone(event.currentTarget.value)}
                  />
                  {/* every input needs this */}

                </FormControl>
              </HStack>

              <HStack>
                <FormControl id="email">
                  <Input type="email" placeholder="Email"
                    isInvalid={emailInValid}
                    errorBorderColor="red.300"
                    onChange={event => setEmail(event.currentTarget.value)}
                  />
                </FormControl>
              </HStack>

            </Stack>

            <Stack pt={6}>
              <Checkbox colorScheme="gray" size="sm" fontWeight='semibold' value="Y"
                onChange={event => setRewardPointsTrue(event.currentTarget.value)}
              >USE POINTS / CERTIFICATES</Checkbox>
              <Checkbox colorScheme="gray" size="sm" fontWeight='semibold' value="Y"
                onChange={event => setFlexibleDatesTrue(event.currentTarget.value)}
              >MY DATES ARE FLEXIBLE</Checkbox>
            </Stack>

            <Stack spacing={10}>
              <Button
                onClick={() => doSubmitBooking()}
                mt={4}
                bg={'black'}
                fontSize={'sm'}
                letterSpacing={1}
                textTransform={'uppercase'}
                color={'gray.100'}
                _hover={{
                  bg: 'black',
                  color: 'white',
                  transform: "scale(0.98)",
                }}
                _active={{
                  bg: 'gray.800',
                  transform: "scale(0.96)",
                }}
              >
                Submit Your Booking Enquiry</Button>

              <ConfirmationModal isOpen={showResult} />
              <Text
                fontSize='sm'
                fontWeight='thin'>
                Please kindly note: 
                <br />
                • The above pricing is not applicable to blackout dates. 
                <br />
                • Blackout dates are
                <br />
                2022: Sep 10-12, Oct 1-4, Dec 24-27, Dec 31, 2022-Jan 2, 2023
                <br />
                2023: Jan 21-25, Apr 7-10, Apr 30-May 1, May 26, Jun 22, Jul 1-2
                <br />
                • Special offers are available upon booking via this online form or via phone or email reservations@twentyonewhitfield.com
                <br />
                • Upon submission of this form, we will contact you with the pricing and availability
                for you to confirm.
                Your reservation is not yet confirmed until then.
                <br />
                • Full prepayment is required for the entire stay at time of reservation
                and will be charged to the credit card provided. The amount is
                non-refundable in the event of any cancellation or amendment.
                <br />
                • The rate is subject to a 10% service charge {'&'} prevailing government
                tax (currently 0%) per room per night.
                <br />
                • Advance reservation is required.
                <br />
                • Please use this form to contact us regarding the final rate. <br />
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

// function Yourdetails() {
//   return (
//     <Flex>
//       <Text pt={4} fontSize="3xl" fontWeight='thin'>Your Details</Text>

//       <HStack>
//         <FormControl id="address">
//           <Input type="string" placeholder="Street Address" />
//         </FormControl>
//         <FormControl id="city">
//           <Input type="string" placeholder="City" />
//         </FormControl>
//       </HStack>
//       <HStack>
//         <FormControl id="province">
//           <Input type="string" placeholder="Province or State" />
//         </FormControl>
//         <FormControl id="others">
//           <Input type="string" placeholder="Others" />
//         </FormControl>
//       </HStack>
//       <HStack>
//         <FormControl id="zip">
//           <Input type="number" placeholder="Zip" />
//         </FormControl>
//         <FormControl id="country">
//           <Input type="string" placeholder="Country" />
//         </FormControl>
//       </HStack>

//     </Flex>
//   );
// }
function ConfirmationModal(props) {
  const { onClose } = useDisclosure();

  return (

    <Modal isOpen={props.isOpen} onClose={onClose}
      zIndex={'9999'}
      bottom={0} h='100%' w='100%' size='full'
      mx={14}
    >
      {/* <ModalOverlay /> */}

      <ModalContent m={1}>
        {/* <ModalHeader>Modal Title</ModalHeader> */}

        <ModalCloseButton p={5} onClick={() => window.location.href = '/'}
          _focusVisible='false'
          outline='none'
          _hover={{
            transform: "scale(0.98)",
            color: 'gray.800',
            borderColor: 'gray.800',
          }}
          _active={{
            transform: "scale(0.96)",
            color: 'gray.900',
            borderWidth: '3'
          }}

        />
        <ModalBody>
          <ConfirmationModalMessage />
        </ModalBody>

        <ModalFooter>
          {/*     <Button
         onClick={onClose}
         mt={4}
         bg={'black'}
         fontSize={'sm'}
         letterSpacing={1}
         textTransform={'uppercase'}
         color={'gray.100'}
         _hover={{
           bg: 'black',
           color: 'white',
           transform: "scale(0.98)",
         }}
         _active={{
           bg: 'gray.800',
           transform: "scale(0.96)",
         }}
        >
done</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>

  )
}

function ConfirmationModalMessage() {
  return (
    <Box>
      <Center minH='90vh'>
        <VStack spacing={8} textAlign='center'>
          <Image pb={4} w={{ base: "150px", md: "180px" }} src={logofull} alt='Twenty One Whitfield Hotels and Serviced Apartments' />

          <Text pt={4} fontSize={{ base: '2xl', md: '3xl' }} fontWeight='thin'>We Look Forward to Welcoming You</Text>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            fontWeight='thin'>
            Thank you for your booking enquiry. One of our team will be
            in touch with you shortly.</Text>
        </VStack>
      </Center>
      <Center><Socials /></Center>
    </Box>
  );
}

function InstantRebate() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize={{ base: 'sm', md: 'xl' }}
      lineHeight='0.9'
      fontWeight='thin'>
      instant <br />rebate</Text>
  );
}
function LoyaltyRewardPoints() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize={{ base: 'sm', md: 'xl' }}
      lineHeight='0.9'
      fontWeight='thin'>
      loyalty <br />reward points</Text>
  );
}

function BookTenOneFree() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize={{ base: 'sm', md: 'xl' }}
      lineHeight='0.9'
      fontWeight='thin'>
      book 10 nights <br />get 1 free</Text>
  );
}

function SpecialUpgradeRate() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize={{ base: 'sm', md: 'xl' }}
      lineHeight='0.9'
      fontWeight='thin'>
      special <br /> upgrade rate</Text>
  );
}

function Ampersand() {
  return (
    <Text
      w='25%'
      textTransform='uppercase'
      fontFamily='serif'
      fontSize='xl'
      lineHeight='0.9'
      color='gray.300'
      fontWeight='thin'>
      {'&'}</Text>
  );
}

