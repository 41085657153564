import {
  Text,
    Stack,
    useColorModeValue,
    useBreakpointValue,
  } from '@chakra-ui/react';
  import React from 'react';

export default function Logotextual() {
  return(

            <Stack mt={{ base: 'none', md: 1 }} minW='157px' textAlign={useBreakpointValue({ base: 'center'})}>
              <Text textAlign='center'
                fontSize='12px'
                fontWeight='medium'
                textTransform="uppercase"
                letterSpacing={2}
                color={useColorModeValue('gray.900', 'white')}
              >twenty one
                <br /> <Text fontWeight='black' letterSpacing={3.5}>whitfield</Text>
              </Text>
            </Stack>

)
}